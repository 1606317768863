<template>
    <div :class="`m-3 p-2 wrapper  wrapper--${table_bu}`">
        <Loading v-if="loading" />
        <h2 class="text-center" v-if="!loading">Verkoop orders - {{ bu }}</h2>
        <Tabs v-if="!loading">
            <Tab title="Totaal" class="p-2">
                <button class="m2 employee_download" @click="createExcel">Totaallijst voertuigen</button>
                <table :class="`w-100 p-2 table--default table__border--${table_bu}`">
                    <thead :class="`table__head--${table_bu}`">
                        <tr>
                            <th class="table__head--text">Naam Verkoper</th>
                            <th class="table__head--text">Aantal</th>
                            <th class="table__head--text table__data--right">Prijs</th>
                            <th class="table__head--text table__data--right">Nettoprijs</th>
                            <th class="table__head--text table__data--right">
                                Dagen verkocht
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(sales, key) in purchase_duo_sales_orders_total" :key="key"
                            :class="`table__row--color`">
                            <td class="table__cell--default">{{ key }}</td>
                            <td class="table__cell--default">{{ sales.aantal }}</td>
                            <td class="table__data--right table__cell--default">
                                € {{ sales.prijs }}
                            </td>
                            <td class="table__data--right table__cell--default">
                                € {{ sales.nettoprijs }}
                            </td>
                            <td class="table__data--right table__cell--default">
                                {{ sales.dagenverkocht }}
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr class="table__foot">
                            <th class="table__foot--padding">Totaal:</th>
                            <th class="table__foot--padding">{{ purchase_duo_sales_total_all.aantal }}</th>
                            <th class="table__data--right table__foot--padding">€ {{ purchase_duo_sales_total_all.prijs
                                }}
                            </th>
                            <th class="table__data--right table__foot--padding">
                                € {{ purchase_duo_sales_total_all.nettoprijs }}
                            </th>
                            <th class="table__data--right table__foot--padding">
                                {{ purchase_duo_sales_total_all.dagenverkocht }}
                            </th>
                        </tr>
                    </tfoot>
                </table>
            </Tab>
            <Tab v-for="(salesman, key) in purchase_duo_sales_orders" :key="key" :title="`${key}`" show>
                <SalesOrderTable v-if="!loading" :table_data="salesman"
                    :table_data_total="purchase_duo_sales_order_totals[key]" :table_style="`${table_bu}`"
                    :person_data="`${key}`" />
            </Tab>
        </Tabs>
    </div>
</template>

<script>
import request from "@/functions/request.js";
import Loading from "@/components/Loading";
import SalesOrderTable from "@/components/SalesOrderTable.vue";
import Tabs from "@/components/Tabs/Tabs.vue";
import Tab from "@/components/Tabs/Tab.vue";
import * as XLSX from "xlsx";

export default {
    props: ["bu"],
    components: { Loading, SalesOrderTable, Tabs, Tab },
    data: () => ({
        table_bu: null,
        purchase_duo_sales_orders: null,
        purchase_duo_sales_order_totals: null,
        purchase_duo_sales_orders_total: null,
        purchase_duo_sales_total_all: null,
        add_note_itemno: null,
        note_model: null,
        excel_export_data: null,
        loading: true,
    }),
    created() {
        this.getData(this.bu);
    },
    watch: {
        bu(newbu) {
            this.loading = true;
            this.purchase_duo_sales_orders = null;
            this.purchase_duo_sales_order_totals = null;
            this.purchase_duo_sales_orders_total = null;
            this.purchase_duo_sales_total_all = null;
            this.getData(newbu);
        },
    },
    methods: {
        async getData(bu) {
            (bu == 'trucks' || bu == 'trailers') ? this.table_bu = '200' : this.table_bu = '210'
            const sales_orders_url = `purchaseduo/sales-orders/${bu}`;
            const sales_total_url = `purchaseduo/sales-orders-total/${bu}`;
            await request(sales_orders_url, "GET").then(({ purchase_duo_sales_orders, purchase_duo_sales_order_totals }) => {
                this.purchase_duo_sales_order_totals = purchase_duo_sales_order_totals;
                this.purchase_duo_sales_orders = this.sortKeysByLastName(purchase_duo_sales_orders);

            });


            await request(sales_total_url, "GET").then(
                ({ purchase_duo_sales_orders_total, purchase_duo_sales_total_all, excel_export_data }) => {
                    this.purchase_duo_sales_orders_total = purchase_duo_sales_orders_total;
                    this.purchase_duo_sales_total_all = purchase_duo_sales_total_all;
                    this.excel_export_data = excel_export_data;
                    this.loading = false;

                }
            );
        },

        sortKeysByLastName(obj) {
            const sortedKeys = Object.keys(obj).sort((a, b) => {
                const lastNameA = a.split(" ").pop();
                const lastNameB = b.split(" ").pop();
                return lastNameA.localeCompare(lastNameB);
            });

            const sortedObj = {};
            sortedKeys.forEach(key => {
                sortedObj[key] = obj[key];
            });

            return sortedObj;
        },

        createExcel() {
            this.sales_orders_excel = [];

            this.excel_export_data.forEach((i) => {
                this.sales_orders_excel.push({
                    "Verkopernaam": i.verkopernaam,
                    "Nummer": i.itemno,
                    "Soortnaam": i.soortnaam,
                    "Opbouwnaam": i.opbouwnaam,
                    "Merktype": i.merktype,
                    "Regjaar": Number(i.regjaar),
                    "Prijs": Number(i.prijs.replace(/,/g, '')),
                    "Netto verkoopprijs": Number(i.nettoprijs.replace(/,/g, '')),
                    "Betaald": Number(i.Betaald.replace(/,/g, '')),
                    "Datum": i.datum,
                    "Leveringsvoorwaarde": i.leveringsvoorwaarde,
                    "klant": i.klant,
                    "Klant naam": i.naam,
                    "Klant adres": i.adres,
                    "Klant stad": i.stad,
                    "Klant land": i.landnaam,
                    "RDW status": (i.RdwStatus == null) ? "" : i.RdwStatus,
                    "RDW Laatste aanpassing": (i.LaatsteAanpassing == null) ? "" : i.LaatsteAanpassing,
                    "Dagen V": Number(i.dagenverkocht),
                    "Laatste notitie": i.notes ? i.notes[0].Aantekeningen : "",
                    "Geplaatst door":  i.notes ? i.notes[0].Gebruiker : "",
                    "Datum Notitie":  i.notes ? i.notes[0].datum : "",



                });
            });

            var telWS = XLSX.utils.json_to_sheet(this.sales_orders_excel);
            var wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(
                wb,
                telWS,
                `salesorders_${this.bu}_${this.moment().format("DD-MM-YYYY")}`
            );
            XLSX.writeFile(
                wb,
                `salesorders_${this.bu}_${this.moment().format("DD-MM-YYYY")}.xlsx`
            );
        }
    }
};
</script>
